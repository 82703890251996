var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "head-info", class: _vm.center && "center" },
    [
      _c("span", [_vm._v(_vm._s(_vm.title))]),
      _c("p", [_vm._v(_vm._s(_vm.content))]),
      _vm.bordered ? _c("em") : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }